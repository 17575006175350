import React from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'

const Migrate = () => {
  const { t } = useTranslation()
  return (
    <section id="sectionMigrate" className="migrate">
      <div className=" container ">
        <h2 className="migrate__title">
          {t('page_home_section_migrate_title')}
        </h2>
        <div className="migrate__container">
          <div className="migrate__image">
            <StaticImage
              src="../../assets/images/laptop.png"
              alt={t('page_home_section_migrate_title')}
            />
          </div>
          <div className="migrate__content">
            <h3 className="migrate__subtitle h4-style">
              {t('page_home_section_migrate_subtitle')}
            </h3>
            <p className="text-primary secondary">
              {t('page_home_section_migrate_paragraph')}
            </p>
            <div className="col-12 migrate__content-btn">
              <Link
                to={'/migrate'}
                className=" btn btn-primary template__btn migrate__button"
              >
                {t('page_home_section_3_btn')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Migrate
